body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: #F8FBFB;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

ul, li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

* {
  outline: none;
}

h1, h2, h3, h4, h5 {
  padding: 0;
  margin: 0;
}

html, body {
  width: 100%;
  height: 100%;
}

#root {
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;
}

table, td, th, tr {
  border-collapse: collapse;
  font-weight: inherit;
}