body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: #F8FBFB;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

ul, li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

* {
  outline: none;
}

h1, h2, h3, h4, h5 {
  padding: 0;
  margin: 0;
}

html, body {
  width: 100%;
  height: 100%;
}

#root {
  flex: 1 1;
  display: flex;
  width: 100%;
  height: 100%;
}

table, td, th, tr {
  border-collapse: collapse;
  font-weight: inherit;
}
@font-face {
  font-family: 'SF UI Display Ultralight';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Ultralight'), url('/fonts/sf-ui-display-ultralight-58646b19bf205.woff') format('woff');
}

@font-face {
  font-family: 'SF UI Display Thin';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Thin'), url('/fonts/sf-ui-display-thin-58646e9b26e8b.woff') format('woff');
}

@font-face {
  font-family: 'SF UI Display Light';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Light'), url('/fonts/sf-ui-display-light-58646b33e0551.woff') format('woff');
}
 
@font-face {
  font-family: 'SF UI Display Medium';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Medium'), url('/fonts/sf-ui-display-medium-58646be638f96.woff') format('woff');
}

@font-face {
  font-family: 'SF UI Display Semibold';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Semibold'), url('/fonts/sf-ui-display-semibold-58646eddcae92.woff') format('woff');
}

@font-face {
  font-family: 'SF UI Display Bold';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Bold'), url('/fonts/sf-ui-display-bold-58646a511e3d9.woff') format('woff');
}

@font-face {
  font-family: 'SF UI Display Heavy';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Heavy'), url('/fonts/sf-ui-display-heavy-586470160b9e5.woff') format('woff');
}

@font-face {
  font-family: 'SF UI Display Black';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Display Black'), url('/fonts/sf-ui-display-black-58646a6b80d5a.woff') format('woff');
}

